import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Popper, ThemeProvider } from '@mui/material';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isElementNode,
  $isDecoratorNode,
  $isTextNode,
  $nodesOfType,
} from 'lexical';
import {
  BeautifulMentionNode,
  BeautifulMentionsPlugin,
  BeautifulMentionsTheme,
} from 'lexical-beautiful-mentions';

import OnChangePlugin from './plugins/OnChangePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import EventPlugin from './plugins/EventPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';

import InterfaceController, { ListenEvent } from '../../InterfaceController';
import ExampleTheme from './ExampleTheme';
import ErrorFallback from '../../components/ErrorFallback';
import PPSocket from '../../classes/SocketClass';
import HybridNode2 from '../../classes/HybridNode2';
import { BooleanType } from '../datatypes/booleanType';
import { CodeType } from '../datatypes/codeType';
import { ColorType } from '../datatypes/colorType';
import { JSONType } from '../datatypes/jsonType';
import { StringType } from '../datatypes/stringType';
import { convertToString } from '../../utils/utils';
import { TNodeSource, TRgba } from '../../utils/interfaces';
import {
  PIXI_TRANSPARENT_ALPHA,
  COLOR_WHITE,
  NODE_TYPE_COLOR,
  SOCKETNAME_BACKGROUNDCOLOR,
  SOCKET_TYPE,
  customTheme,
} from '../../utils/constants';
import { DynamicInputNodeFunctions } from '../abstract/DynamicInputNode';
import {
  CustomBeautifulMentionNodes,
  InputParameterMenu,
  InputParameterMenuItem,
} from './plugins/CustomBeautifulMentionNodes';
import Socket from '../../classes/SocketClass';

const beautifulMentionsTheme: BeautifulMentionsTheme = {
  '@': 'px-1',
  '@Focused': 'inline-code-span-focus',
};

const initialValue = `{
  "root": {
    "children": [
      {
        "children": [],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "paragraph",
        "version": 1
      }
    ],
    "direction": null,
    "format": "",
    "indent": 0,
    "type": "root",
    "version": 1
  }
}`;
const plainOutputSocketName = 'Plain';
const markdownOutputSocketName = 'Markdown';
const htmlOutputSocketName = 'HTML';
const textJSONSocketName = 'textJSON';
const autoHeightName = 'Auto height';
const inputPrefix = 'Input';
const backgroundColor = TRgba.fromString(COLOR_WHITE);

const Placeholder: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '32px',
        left: '26px',
        opacity: 0.5,
        pointerEvents: 'none',
      }}
    >
      Start writing...
    </Box>
  );
};

export class TextEditor2 extends HybridNode2 {
  textToImport: { html: string } | { plain: string };
  eventTarget: EventTarget;

  public getName(): string {
    return 'Text editor';
  }

  public getDescription(): string {
    return 'Adds a rich text editor which allows to embed input data';
  }

  public getTags(): string[] {
    return ['Draw'].concat(super.getTags());
  }

  getShowLabels(): boolean {
    return false;
  }

  getOpacity(): number {
    return PIXI_TRANSPARENT_ALPHA;
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.OUT,
        plainOutputSocketName,
        new StringType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.OUT,
        markdownOutputSocketName,
        new StringType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.OUT,
        htmlOutputSocketName,
        new CodeType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        textJSONSocketName,
        new JSONType(),
        initialValue,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        backgroundColor,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        autoHeightName,
        new BooleanType(),
        true,
        false,
      ),
    ];
  }

  public getMinNodeHeight(): number {
    return 30;
  }

  public getDefaultNodeWidth(): number {
    return 400;
  }

  public getDefaultNodeHeight(): number {
    return 400;
  }

  public getNewInputSocketName() {
    return super.getNewInputSocketName('Input');
  }

  public getSocketForNewConnection = (socket: PPSocket): PPSocket => {
    if (!socket.isInput()) {
      return DynamicInputNodeFunctions.getSocketForNewConnection(
        socket,
        this,
        true,
      );
    }
    return this.getOutputSocketByName(plainOutputSocketName);
  };

  public async inputPlugged() {
    await super.inputPlugged();
    this.adaptInputs();
    this.drawSockets();
  }

  public async inputUnplugged() {
    await DynamicInputNodeFunctions.inputUnplugged(this);
    await super.inputUnplugged();
    this.adaptInputs();
  }

  protected adaptInputs(): void {
    this.eventTarget.dispatchEvent(new Event('adaptInputs'));
  }

  focus = () => {
    this.eventTarget.dispatchEvent(new Event('focus'));
  };

  public onNodeAdded = async (source?: TNodeSource): Promise<void> => {
    this.eventTarget = new EventTarget();
    if (this.initialData) {
      // prevents canvas overflow issue when pasting text
      // but the underlying issue is still not solved - https://trello.com/c/n836zbE7
      this.setInputData(autoHeightName, false);
    }
    super.onNodeAdded(source);
  };

  public shouldFocusWhenNew(): boolean {
    return true;
  }

  public async populateDefaults(socket: Socket): Promise<void> {
    const dataToUpdate = convertToString(socket.defaultData);
    const inputData = convertToString(this.getInputData(textJSONSocketName));
    if (initialValue === inputData) {
      this.setInputData(textJSONSocketName, dataToUpdate);
      this.eventTarget.dispatchEvent(new Event('replaceText'));
    }
    await super.populateDefaults(socket);
  }

  // small presentational component
  getParentComponent(props: any): any {
    const node = props.node;
    const editorRef = useRef(null);
    const popperRef = useRef(null);
    const [editorState, setEditorState] = useState<string>();
    const [contentHeight, setContentHeight] = useState(0);
    const [color, setColor] = useState(
      props[SOCKETNAME_BACKGROUNDCOLOR] || backgroundColor,
    );
    const [contrastColor, setContrastColor] = useState(backgroundColor);
    const [openToolbar, setOpenToolbar] = useState(false);
    const [inputParameterArray, setInputParameterArray] = useState({ '@': [] });
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
    const [floatingAnchorElem, setFloatingAnchorElem] =
      useState<HTMLDivElement | null>(null);
    const onRef = useCallback((_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem);
      }
    }, []);

    const editorConfig = useMemo(
      () => ({
        namespace: 'MyEditor',
        theme: {
          ...ExampleTheme,
          beautifulMentions: beautifulMentionsTheme,
        },
        editorState,
        onError(error) {
          throw error;
        },
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
          BeautifulMentionNode,
          ...CustomBeautifulMentionNodes,
        ],
      }),
      [],
    );

    const focusEditor = (state) => {
      setOpenToolbar(state);
      editorRef.current.update(() => {
        editorRef.current.setEditable(state);
      });
    };

    const onChange = useCallback(async (editorState) => {
      const editorStateJSON = editorState.toJSON();
      const stringifiedState = JSON.stringify(editorStateJSON);
      setEditorState(stringifiedState);
      node.setInputData(textJSONSocketName, stringifiedState);
      updateOutputs();
      const target = document.getElementById(node.id);
      setContentHeight(target.scrollHeight);
    }, []);

    const updateOutputs = useCallback(() => {
      if (editorRef.current) {
        editorRef.current.getEditorState().read(() => {
          const plainString = $getRoot().getTextContent();
          const markdownString = $convertToMarkdownString(TRANSFORMERS);
          const htmlString = $generateHtmlFromNodes(editorRef.current, null);
          node.setOutputData(plainOutputSocketName, plainString);
          node.setOutputData(markdownOutputSocketName, markdownString);
          node.setOutputData(htmlOutputSocketName, htmlString);
        });
      }
      node.executeChildren();
    }, []);

    const getAllInputParameters = useCallback((): string => {
      const allParameters = node.inputSocketArray.filter((input: PPSocket) => {
        return input.name.startsWith(inputPrefix);
      });

      if (allParameters.length === 0) {
        // No parameter sockets found
        return undefined;
      }

      const dataObject: Record<string, any> = {};
      allParameters.map((parameter) => {
        // if no link, then return data
        if (parameter.links.length === 0) {
          dataObject[parameter.name] = parameter.data;
          return;
        }

        const link = parameter.links[0];
        dataObject[parameter.name] = String(link.source.data);
      });

      return JSON.stringify(dataObject);
    }, []);

    const updateInputParameterMentionNodes = useCallback(
      (socketname, oldValue, newValue) => {
        if (editorRef.current) {
          editorRef.current.update(() => {
            const mentionNodes = $nodesOfType(CustomBeautifulMentionNodes[0]);
            for (const node of mentionNodes) {
              const data = node.getData();
              if (data.socketname === socketname && oldValue !== newValue) {
                node.setValue(newValue);
              }
            }
          });
        }
      },
      [editorRef.current],
    );

    const updateEditorDataFromInputParameters = () => {
      inputParameterArray['@'].forEach((input) => {
        updateInputParameterMentionNodes(
          input.socketname,
          input.value,
          convertToString(props[input.socketname]),
        );
      });
    };

    const updatePopper = useCallback(() => {
      if (popperRef.current) {
        popperRef.current.update();
      }
    }, [popperRef]);

    const adaptInputs = () => {
      const newInputArray = node.inputSocketArray
        .filter((item) => item.name.startsWith(inputPrefix))
        .map((item, index) => {
          return {
            id: index,
            socketname: item.name,
            value: node.getInputData(item.name),
          };
        });
      setInputParameterArray({ '@': newInputArray });
    };

    const replaceText = () => {
      editorRef.current.update(() => {
        const root = $getRoot();
        root.getChildren().forEach((n) => n.remove());
        const paragraphNode = $createParagraphNode();
        const text = node.getInputData(textJSONSocketName);
        const lines = text.split('\n');
        lines.forEach((line, index) => {
          const textNode = $createTextNode(line);
          paragraphNode.append(textNode);
          if (index < lines.length - 1) {
            paragraphNode.append($createTextNode('\n'));
          }
        });
        root.append(paragraphNode);
        node.executeChildren();
      });
    };

    useEffect(() => {
      const handleFocus = () => {
        focusEditor(true);
        // set caret to end when placed
        editorRef.current.update(() => {
          const root = $getRoot();
          const lastNode = root.getLastDescendant();
          lastNode.selectEnd();
        });
      };

      const handleAdaptInputs = () => {
        adaptInputs();
      };
      const handleReplaceText = () => {
        replaceText();
      };

      node.eventTarget.addEventListener('focus', handleFocus);
      node.eventTarget.addEventListener('adaptInputs', handleAdaptInputs);
      node.eventTarget.addEventListener('replaceText', handleReplaceText);

      adaptInputs();

      if (editorRef.current) {
        Promise.resolve().then(() => {
          // prevent react error: flushSync was called from inside a lifecycle method
          if (node.initialData) {
            editorRef.current.update(() => {
              const root = $getRoot();
              root.getChildren().forEach((n) => n.remove());
              if (node.initialData?.['html']) {
                const parser = new DOMParser();
                const dom = parser.parseFromString(
                  node.initialData['html'],
                  'text/html',
                );
                const nodes = $generateNodesFromDOM(editorRef.current, dom);
                const validNodes = nodes.filter(
                  (n) =>
                    $isElementNode(n) || $isDecoratorNode(n) || $isTextNode(n),
                );
                validNodes.forEach((n) => {
                  if ($isElementNode(n) || $isDecoratorNode(n)) {
                    root.append(n);
                  } else if ($isTextNode(n)) {
                    const paragraphNode = $createParagraphNode();
                    paragraphNode.append(n);
                    root.append(paragraphNode);
                  }
                });
              } else if (node.initialData?.['plain']) {
                const paragraphNode = $createParagraphNode();
                const text = node.initialData['plain'];
                const lines = text.split('\n');
                lines.forEach((line, index) => {
                  const textNode = $createTextNode(line);
                  paragraphNode.append(textNode);
                  if (index < lines.length - 1) {
                    paragraphNode.append($createTextNode('\n'));
                  }
                });
                root.append(paragraphNode);
              }
            });
          } else {
            const initialEditorState = editorRef.current.parseEditorState(
              props[textJSONSocketName],
            );
            editorRef.current.setEditorState(initialEditorState);
            setEditorState(props[textJSONSocketName]);
          }
        });
      }

      const ids = [];
      ids.push(
        InterfaceController.addListener(
          ListenEvent.ViewportMoveEnded,
          updatePopper,
        ),
      );

      return () => {
        ids.forEach((id) => InterfaceController.removeListener(id));
        node.eventTarget.removeEventListener('focus', handleFocus);
        node.eventTarget.removeEventListener('adaptInputs', handleAdaptInputs);
        node.eventTarget.removeEventListener('replaceText', handleReplaceText);
      };
    }, []);

    useEffect(() => {
      if (props[autoHeightName]) {
        node.resizeAndDraw(node.nodeWidth, contentHeight + 32); // add container padding
      }
    }, [contentHeight, props[autoHeightName]]);

    useEffect(() => {
      focusEditor(props.isFocused);
    }, [props.isFocused]);

    useEffect(() => {
      updateEditorDataFromInputParameters();
    }, [getAllInputParameters(), props[textJSONSocketName]]);

    useEffect(() => {
      setColor(props[SOCKETNAME_BACKGROUNDCOLOR]);
      setContrastColor(
        props[SOCKETNAME_BACKGROUNDCOLOR].getContrastTextColor(),
      );
    }, [
      props[SOCKETNAME_BACKGROUNDCOLOR].r,
      props[SOCKETNAME_BACKGROUNDCOLOR].g,
      props[SOCKETNAME_BACKGROUNDCOLOR].b,
      props[SOCKETNAME_BACKGROUNDCOLOR].a,
    ]);

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={customTheme}>
          <LexicalComposer initialConfig={editorConfig}>
            <EditorRefPlugin editorRef={editorRef} />
            <Box
              sx={{
                background: `${color}`,
                color: `${contrastColor}`,
                p: 2,
                position: 'relative',
                lineHeight: '20px',
                fontWeight: 400,
                textAlign: 'left',
                height: '100%',
                boxSizing: 'border-box',
                overflow: 'hidden',
              }}
              ref={onRef}
            >
              <Popper
                popperRef={popperRef}
                open={openToolbar}
                anchorEl={node.container}
                placement="top"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 8],
                    },
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'viewport',
                      rootBoundary: 'viewport',
                      altAxis: true,
                      padding: 8,
                    },
                  },
                ]}
              >
                <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
              </Popper>
              <EventPlugin />
              <RichTextPlugin
                contentEditable={
                  <ContentEditable id={node.id} className="editor-input" />
                }
                placeholder={<Placeholder />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnChangePlugin onChange={onChange} />
              <HistoryPlugin />
              <AutoFocusPlugin />
              <CodeHighlightPlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <TabIndentationPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              <BeautifulMentionsPlugin
                items={inputParameterArray}
                menuComponent={InputParameterMenu}
                menuItemComponent={InputParameterMenuItem}
              />
              {floatingAnchorElem && (
                <>
                  {/* <DraggableBlockPlugin anchorElem={floatingAnchorElem} /> */}
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElem}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                </>
              )}
            </Box>
          </LexicalComposer>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}
