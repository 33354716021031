import PPGraph from '../../classes/GraphClass';
import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import {
  NODE_CORNERRADIUS,
  NODE_MARGIN,
  NODE_TYPE_COLOR,
  SOCKET_TYPE,
  SOCKET_WIDTH,
} from '../../utils/constants';
import { TRgba } from '../../utils/interfaces';
import { AnyType } from '../datatypes/anyType';
import { ensureVisible, getObjectsInsideBounds } from '../../pixi/utils-pixi';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import { DynamicEnumType } from '../datatypes/dynamicEnumType';
import debounce from 'lodash/debounce';
import * as PIXI from 'pixi.js';
import InputArrayKeysType from '../datatypes/inputArrayKeysType';
import { JSONArrayType } from '../datatypes/jsonArrayType';
import { arrayEntryToSelectedValue } from '../data/dataFunctions';
import { PNPHitArea } from '../../classes/selection/PNPHitArea';
import { deSerializeType, serializeType } from '../datatypes/typehelper';

export const macroOutputName = 'Output';
export const macroNameName = 'Macro';

export const EMPTY_DEFAULT_MACRO_NAME = 'EmptyDefaultMacro';

const macroArrayName = 'Array';

const MACRO_INPUT_BLOCK_SIZE = 120;
const MACRO_OUTPUT_BLOCK_SIZE = 60;

const macroColor = TRgba.fromString(NODE_TYPE_COLOR.MACRO);

export class Macro extends PPNode {
  executingFromOutside = 0;
  textRef: PIXI.Text = undefined;

  public getName(): string {
    return 'Macro';
  }

  public getDescription(): string {
    return 'Wrap a group of nodes into a macro and use this Macro as often as you want';
  }

  public getTags(): string[] {
    return ['Macro'].concat(super.getTags());
  }

  public getMinNodeWidth(): number {
    return MACRO_INPUT_BLOCK_SIZE * 3;
  }

  public getDefaultNodeWidth(): number {
    return 1000;
  }

  public getDefaultNodeHeight(): number {
    return 300;
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(false, true, false, 1000, this);
  }

  getColor(): TRgba {
    return macroColor;
  }

  private getMacroText(): string {
    let toReturn = this.nodeName + ': (';
    const linkedOutputs = this.outputSocketArray.filter((socket) =>
      socket.hasLink(),
    );
    toReturn += linkedOutputs
      .map(
        (socket) =>
          this.getSocketDisplayName(socket) + ': ' + socket.dataType.getName(),
      )
      .join(',');
    toReturn += ') => ' + this.inputSocketArray[0].dataType.getName();
    return toReturn;
  }

  public drawBackground(): void {
    this._BackgroundGraphicsRef.removeChildren();
    const textRef = new PIXI.Text({ text: this.getMacroText() });
    textRef.y = -50;
    textRef.x = 50;
    textRef.style.fill = new TRgba(128, 128, 128).hexNumber();
    textRef.style.fontSize = 36;
    this._BackgroundGraphicsRef.addChild(textRef);

    this._BackgroundGraphicsRef.moveTo(10, 1.5);
    this._BackgroundGraphicsRef.lineTo(this.nodeWidth - 20, 1.5);
    this._BackgroundGraphicsRef.moveTo(10, this.nodeHeight - 1.5);
    this._BackgroundGraphicsRef.lineTo(
      this.nodeWidth - 20,
      this.nodeHeight - 1.5,
    );
    this._BackgroundGraphicsRef.stroke({
      width: 3,
      color: this.getColor(),
      alpha: 0.5,
    });

    this.drawBlock(this._BackgroundGraphicsRef, this.getLeftBlock());
    this.drawBlock(this._BackgroundGraphicsRef, this.getRightBlock());

    this._BackgroundGraphicsRef.fill({
      color: this.getColor().hexNumber(),
      alpha: this.getOpacity(),
    });
  }

  public getInputSocketXPos(): number {
    return this.nodeWidth - MACRO_OUTPUT_BLOCK_SIZE;
  }
  public getOutputSocketXPos(): number {
    return MACRO_INPUT_BLOCK_SIZE;
  }
  public async outputPlugged(): Promise<void> {
    await super.outputPlugged();
    const last = this.outputSocketArray[this.outputSocketArray.length - 1];
    // if furthest down parameter is plugged in, add a new one
    if (last.hasLink()) {
      this.addDefaultOutput();
    }
    await this.updateAllCallers();
    this.drawNodeShape();
  }

  public async outputUnplugged(): Promise<void> {
    await super.outputUnplugged();
    await this.updateAllCallers();
    this.drawNodeShape();
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.OUT, 'Parameter 1', new AnyType()),
      new Socket(SOCKET_TYPE.IN, macroOutputName, new AnyType()),
    ];
  }

  public addDefaultOutput(): void {
    this.addOutput(
      this.constructSocketName('Parameter', this.outputSocketArray),
      new AnyType(),
    );
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }

  public async executeMacro(args: any[]): Promise<any> {
    this.executingFromOutside++;
    args.forEach((arg, i) => {
      this.setOutputData('Parameter ' + (i + 1), arg);
    });
    await this.executeChildren();
    this.executingFromOutside--;
    return this.getInputData(macroOutputName);
  }

  public socketTypeChanged(): void {
    super.socketTypeChanged();
    this.drawNodeShape();
  }

  // make sure there are no name collisions with other macros
  public setNodeName(text: string): void {
    const existingMacros = PPGraph.currentGraph.macros;
    let nameToUse = text;
    let lastFound = Object.values(existingMacros).find(
      (macro) => macro.nodeName == nameToUse,
    );
    for (
      let i = 0;
      nameToUse == 'Macro' ||
      (lastFound !== undefined && lastFound.id !== this.id);
      i++
    ) {
      nameToUse = text + i.toString();
      lastFound = Object.values(existingMacros).find(
        (macro) => macro.nodeName == nameToUse,
      );
    }
    this.name = nameToUse;
    if (this.hasBeenAdded) {
      this.drawNodeShape();
    }
  }

  public getShrinkOnSocketRemove(): boolean {
    return false;
  }

  public getInsideNodes(): PPNode[] {
    // get all nodes that are within the bounds
    const myBounds = this.getSelectionBounds();
    const nodesInside: PPNode[] = getObjectsInsideBounds(
      Object.values(PPGraph.currentGraph.nodes),
      myBounds[0],
    );
    return nodesInside;
  }

  public getSelectionBounds(): PIXI.Rectangle[] {
    const adjustMargins = (bounds: PIXI.Rectangle) => {
      bounds.x += this.x;
      bounds.y += this.y;

      bounds = PPNode.boundsToSelectionBounds(bounds);

      return bounds;
    };
    const myBounds = [this.getLeftBlock(), this.getRightBlock()].map(
      adjustMargins,
    );
    return myBounds;
  }

  public propagateExecutionPast(): boolean {
    return false;
  }

  public getSocketDisplayName(socket: Socket): string {
    return socket.isOutput() && socket.hasLink() && false
      ? socket.links[0].target
          .name /* this didnt work because it can produce duplicates */
      : socket.name;
  }

  macroDebounceCallerExecutionMS = 100;

  updateAllCallers = debounce(async () => {
    const nodesCallingMe = Object.values(PPGraph.currentGraph.nodes).filter(
      (node) => node.isCallingMacro(this.name),
    );
    // needs to be sequential
    for (let i = 0; i < nodesCallingMe.length; i++) {
      await nodesCallingMe[i].calledMacroUpdated();
    }
  }, this.macroDebounceCallerExecutionMS);

  protected async onExecute(
    _inputObject: any,
    _outputObject: Record<string, unknown>,
  ): Promise<void> {
    // potentially demanding but important QOL, go through all nodes and see which refer to me, they need to be re-executed
    if (this.executingFromOutside == 0) {
      this.updateAllCallers();
    }
  }

  private getLeftBlock(): PIXI.Rectangle {
    return new PIXI.Rectangle(
      NODE_MARGIN,
      0,
      MACRO_INPUT_BLOCK_SIZE,
      this.nodeHeight,
    );
  }

  private getRightBlock(): PIXI.Rectangle {
    return new PIXI.Rectangle(
      NODE_MARGIN + this.nodeWidth - MACRO_OUTPUT_BLOCK_SIZE,
      0,
      MACRO_OUTPUT_BLOCK_SIZE,
      this.nodeHeight,
    );
  }

  private drawBlock(graphics: PIXI.Graphics, block: PIXI.Rectangle) {
    this._BackgroundGraphicsRef.roundRect(
      block.x,
      block.y,
      block.width,
      block.height,
      this.getRoundedCorners() ? NODE_CORNERRADIUS : 0,
    );
  }

  protected getHitArea(): PNPHitArea {
    const leftBlock = PPNode.boundsToSelectionBounds(this.getLeftBlock());
    const rightBlock = PPNode.boundsToSelectionBounds(this.getRightBlock());

    const toReturn = new PNPHitArea((x, y) => {
      return leftBlock.contains(x, y) || rightBlock.contains(x, y);
    });
    return toReturn;
  }
}

export class ExecuteMacro extends PPNode {
  static getMacroOptions(): any[] {
    return Object.values(PPGraph.currentGraph.macros).map((macro) => {
      return { text: macro.nodeName };
    });
  }
  public getName(): string {
    return 'Execute Macro';
  }

  public getNodeTextString(): [string, PIXI.TextStyleFontStyle] {
    const calledMacro = this.getInputData(macroNameName);
    const isCallingMacro = calledMacro !== EMPTY_DEFAULT_MACRO_NAME;
    return isCallingMacro
      ? ['Macro: ' + calledMacro, 'italic']
      : [this.getName(), 'normal'];
  }

  public getDescription(): string {
    return 'Executes a macro that is defined in the graph';
  }

  public getTags(): string[] {
    return ['Macro'].concat(super.getTags());
  }
  onNodeDoubleClick: (event: PIXI.FederatedPointerEvent) => void = () => {
    const macroName = this.getInputData(macroNameName);
    if (macroName !== undefined) {
      ensureVisible([PPGraph.currentGraph.getMacroWithName(macroName)], true);
    }
  };

  getColor(): TRgba {
    return macroColor;
  }

  public isCallingMacro(macroName: string): boolean {
    return (
      super.isCallingMacro(macroName) ||
      this.getInputData(macroNameName) == macroName
    );
  }

  protected getMacroSockets() {
    const calledMacroName = this.getInputData(macroNameName);
    const targetMacro = Object.values(PPGraph.currentGraph.macros).find(
      (macro) => macro.nodeName === calledMacroName,
    );
    if (targetMacro !== undefined) {
      const macrosParams = targetMacro.outputSocketArray;
      if (macrosParams.length > 1) {
        return macrosParams.slice(0, -1); // remove last param if there are more than one
      } else {
        return macrosParams;
      }
    } else {
      return [];
    }
  }

  protected calculateMissingAndExtraSockets(desiredSocketNames: string[]) {
    const socketsToBeRemoved = this.getAllInterestingInputSockets().filter(
      (socket) =>
        socket.name !== macroNameName &&
        desiredSocketNames.find((socketName) => socketName === socket.name) ==
          undefined,
    );
    const socketNamesToBeAdded = desiredSocketNames.filter(
      (socketName) =>
        this.inputSocketArray.find((socket) => socketName === socket.name) ===
        undefined,
    );
    return [socketsToBeRemoved, socketNamesToBeAdded];
  }

  private updateMacroCallSockets(): void {
    const macroSockets = this.getMacroSockets();
    const desiredSocketNames = [macroNameName].concat(
      macroSockets.map((socket) => socket.name),
    );
    const [socketsToBeRemoved, socketNamesToBeAdded] =
      this.calculateMissingAndExtraSockets(desiredSocketNames);
    socketsToBeRemoved.forEach((socket) => {
      this.removeSocket(socket);
    });
    socketNamesToBeAdded.forEach((socketName) => {
      this.addSocket(
        new Socket(
          SOCKET_TYPE.IN,
          socketName,
          deSerializeType(
            serializeType(
              macroSockets.find((socket) => socket.name == socketName).dataType,
            ),
          ),
        ),
      );
    });

    if (socketNamesToBeAdded.length + socketsToBeRemoved.length > 0) {
      this.inputSocketArray.sort((socket1, socket2) => {
        return socket1.name.localeCompare(socket2.name);
      });
      this.resizeAndDraw();
    }
    // align the types of the sockets as well
    macroSockets.forEach((socket) => {
      const inputSocket = this.getInputSocketByName(socket.name);
      if (inputSocket.dataType.getName() !== socket.dataType.getName()) {
        inputSocket.changeSocketDataType(
          deSerializeType(serializeType(socket.dataType)),
        );
      }
    });
  }

  protected macroChangedUpdateInternals(): void {
    this.updateMacroCallSockets();
  }

  public async calledMacroUpdated(): Promise<void> {
    this.macroChangedUpdateInternals();
    await super.calledMacroUpdated();
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        macroNameName,
        new DynamicEnumType(
          () => ExecuteMacro.getMacroOptions(),
          () => {
            this.calledMacroUpdated();
          },
        ),
        EMPTY_DEFAULT_MACRO_NAME,
      ),
      this.getOutputSocket(),
    ].concat(super.getDefaultIO());
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const calledMacro = this.getInputData(macroNameName);
    if (this.getInputData(calledMacro) !== EMPTY_DEFAULT_MACRO_NAME) {
      const args = this.getAllInterestingInputSockets()
        .filter((socket) => socket.name !== macroNameName)
        .map((socket) => socket.data);
      outputObject[macroOutputName] = await PPGraph.currentGraph.invokeMacro(
        calledMacro,
        args,
      );
    }
  }

  protected getOutputSocket() {
    return new Socket(SOCKET_TYPE.OUT, macroOutputName, new AnyType());
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }
}

export class MapExecuteMacro extends ExecuteMacro {
  public getName(): string {
    return 'Map Execute Macro';
  }

  public getDescription(): string {
    return 'Maps an array of JSONs over a macro';
  }

  public getTags(): string[] {
    return ['Macro', 'Array'].concat(super.getTags());
  }

  getPreferredNodesPerSocket(): Map<string, string[]> {
    return new Map([[macroArrayName, ['DRAW_COMBINE_ARRAY']]]);
  }

  private getMacroInputSocket() {
    return new InputArrayKeysType(macroArrayName, this.id);
  }

  private updateMacroFormatSockets(): void {
    const desiredSocketNames = [macroNameName, macroArrayName].concat(
      this.getMacroSockets().map((socket) => socket.name),
    );
    const [socketsToBeRemoved, socketNamesToBeAdded] =
      this.calculateMissingAndExtraSockets(desiredSocketNames);

    socketsToBeRemoved.forEach((socket) => {
      this.removeSocket(socket);
    });
    socketNamesToBeAdded.forEach((socketName) => {
      this.addSocket(
        new Socket(SOCKET_TYPE.IN, socketName, this.getMacroInputSocket()),
      );
    });

    if (socketNamesToBeAdded.length + socketsToBeRemoved.length > 0) {
      this.resizeAndDraw();
    }
  }

  protected macroChangedUpdateInternals(): void {
    this.updateMacroFormatSockets();
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, macroArrayName, new JSONArrayType()),
    ].concat(super.getDefaultIO());
  }

  protected async onExecute(
    inputObject: Record<string, any>,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const calledMacro = this.getInputData(macroNameName);
    const toReturn = [];
    if (calledMacro !== EMPTY_DEFAULT_MACRO_NAME) {
      const inputArray: object[] = inputObject[macroArrayName];

      // hack alert
      const remappings = Object.entries(inputObject).filter((pair) =>
        pair[0].includes('Parameter '),
      );
      for (let i = 0; i < inputArray.length; i++) {
        const args = remappings.map((pair) => {
          return arrayEntryToSelectedValue(inputArray[i], pair[1], i);
        });
        const res = await PPGraph.currentGraph.invokeMacro(calledMacro, args);
        toReturn.push(res);
      }
    }
    outputObject[macroArrayName] = toReturn;
  }

  protected getOutputSocket() {
    return new Socket(SOCKET_TYPE.OUT, macroArrayName, new JSONArrayType());
  }
}
